import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { listPayload, showToast } from "../../../utils/helper";
import { startLoading, stopLoading } from "../../../redux/loader";
import {
  getCustomerById,
  createCustomer,
  updateCustomer
} from "../../../service/customer";
import { listCompany } from "../../../service/company";

export const useAddEditCustomer = (tag, flag = 1) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const loggedInUser = useSelector((state) => state.loggedInUser);

  const [company, setCompany] = useState([]);

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      companyID: loggedInUser?.px_company?.id,
      phoneNumber: "",
      relationName: "",
      address: "",
      addressCover: ""
    },
    mode: "onBlur",
  });

  const isSuperAdmin = useMemo(() => {
    if(loggedInUser && loggedInUser.px_role) {
      return ['super admin'].includes(loggedInUser.px_role.name.toLowerCase());
    }
  }, [loggedInUser]);

  const onSubmit = async (data) => {
    try {
      dispatch(startLoading());
      const payload = {
        userID: loggedInUser.id,
        isActive: true,
        ...data,
      };
      const response = tag === "add" ?
        await createCustomer({ ...payload, createdBy: loggedInUser.id })
      :
        await updateCustomer({ ...payload, updatedBy: loggedInUser.id }, id);
      if (response?.statusCode === 200 && response.success) {
        showToast(response?.message || response?.messageCode, true);
        navigate("/customer");
      } else {
        showToast(response?.message || response?.messageCode, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  };

  const fetchEditCustomerData = useCallback(async () => {
    try {
      if (id) {
        dispatch(startLoading());
        const response = await getCustomerById(id);
        if (response?.statusCode === 200) {
          setValue("name", response.data.name);
          setValue("phoneNumber", response.data.phoneNumber);
          setValue("companyID", response.data?.companyID);
          setValue("address", response.data?.address);
          setValue("relationName", response.data?.relationName);
          setValue("addressCover", response.data?.addressCover);
        } else {
          showToast(response?.message, false);
        }
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  }, [id, dispatch, setValue]);

  useEffect(() => {
    (async () => {
      try {
        const body = listPayload(0, { isActive: true }, 1000);
        const responseCompany = await listCompany(body);
        if(responseCompany.statusCode === 200) {
          const payload = responseCompany?.data?.rows;
          setCompany(payload);
        } else {
          setCompany([]);
        }
      } catch(error) {
        showToast(error.message, false);
      }
    })();
  }, []);

  useEffect(() => {
    tag === "edit" && fetchEditCustomerData();
  }, [tag, fetchEditCustomerData]);

  const cancelHandler = () => {
    navigate("/customer");
  };

  return {
    control,
    company,
    isSuperAdmin,
    onSubmit,
    handleSubmit,
    cancelHandler,
  };
};
