import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import io from 'socket.io-client';

import { getCustomerList } from "../../../service/customer";
import { dropdownItem } from "../../../service/item";
import { listPayload, showToast, showTwoDecimal, showTwoDecimalWithoutRound, convertAmountToWords } from "../../../utils/helper";
import { getBillById, updateBill, createBill } from "../../../service/bill";
import { fetchLoggedInUserData } from "../../../service/loggedInUser";
import { PrintBillContent } from "../../../components/PrintContent";
import { startLoading, stopLoading } from "../../../redux/loader";
import { loggedInUserAction } from "../../../redux/loggedInUser";
// import { socket } from "../../../hook/useSocket";

export const useAddEditCreateBill = (tag) => {
  const dispatch = useDispatch();
  
  const { id } = useParams();
  const loggedInUser = useSelector((state) => state.loggedInUser);

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);

  const [isViewDetailOpen, setIsViewDetailOpen] = useState(false);
  const [isPrintBtn, setIsPrintBtn] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting }
  } = useForm({
    defaultValues: {
      billNo: "",
      companyID: loggedInUser.companyID,
      customerID: "",
      date: moment(new Date()).format('yyyy-MM-DD'),
      grandTotal: '0',
      packingForwardingCharges: '0',
      detail: [
        {
          index: 0,
          itemID: "",
          qty: "",
          rate: "",
          discount: "0",
          total: "",
        },
      ]
    },
    mode: "onBlur",
  });

  const { fields, append, remove } = useFieldArray({
    name: "detail",
    control: control,
  });

  const addRow = () => {
    const index = getValues("detail").length;
    append({
      index: index,
      itemID: "",
      qty: "",
      rate: "",
      discount: "0",
      total: "",
    });
  };

  const removeRow = (index) => {
    const grandTotal = parseFloat(getValues(`grandTotal`) || '0');
    const rowTotal = parseFloat(getValues(`detail.${index}.total`) || '0');
    const total = grandTotal - rowTotal;
    setValue(`grandTotal`, total);
    remove(index);
  };

  // const isAdmin = useMemo(() => {
  //   if (loggedInUser && loggedInUser.px_role && ['admin', 'super admin'].includes(loggedInUser.px_role.name?.toLowerCase())) {
  //     return true;
  //   }
  //   return false;
  // }, [loggedInUser]);

  const selectedCompany = useMemo(() => {
    if (loggedInUser && loggedInUser.px_company) {
      return loggedInUser.px_company.name || ''
    }
    return '';
  }, [loggedInUser]);

  const getNewBillNo = async () => {
    const latestBillNo = localStorage.getItem('latestBillNo');
    const finalBillNumber = latestBillNo;
    setValue("billNo", finalBillNumber);
  };

  const toggleViewDetailOpen = () => {
    setIsViewDetailOpen(!isViewDetailOpen);
  }

  const onSubmit = async (data) => {
    try {
      const detailData = data.detail.map((item) => {
        return {
          itemID: +item.itemID,
          // service: { name: item.serviceID.label },
          qty: +item.qty,
          rate: +item.rate,
          discount: +item.discount,
          total: +item.total,
        };
      });
      dispatch(startLoading());
      const payload = {
        date: data.date,
        billNo: data.billNo,
        companyID: data.companyID,
        customerID: data.customerID,
        grandTotal: data.grandTotal,
        detail: detailData,
        packingForwardingCharges: data.packingForwardingCharges,
      };
      const response = tag === 'add' ? await createBill({...payload, userID: loggedInUser.id, createdBy: loggedInUser.id }) : await updateBill({ ...payload, updatedBy: loggedInUser.id}, id);
      if (response.statusCode === 200) {
        showToast(response.message, true);
        if (tag === "add") {
          // socket.emit(`sendBillToWhatsApp_${loggedInUser.phoneNumber}`, response.data);
          const { success, message, data} = await fetchLoggedInUserData();
          if (success) {
            const latestBillNo = data.latestBillNo;
            localStorage.setItem('latestBillNo', latestBillNo);
            dispatch(loggedInUserAction.storeLoggedInUserData(data));
          } else {
            showToast(message, false);
          }
          reset();
          setValue('date', moment(new Date()).format('yyyy-MM-DD'));
          getNewBillNo();
        } else {
          navigate("/bill");
        }
      } else {
        showToast(response.message, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  };

  const printHandler = async (data) => {
    try {
      const detailData = data.detail.map((item) => {
        return {
          itemID: +item.itemID,
          // service: { name: item.serviceID.label },
          qty: +item.qty,
          rate: +item.rate,
          discount: +item.discount,
          total: +item.total,
        };
      });
      dispatch(startLoading());
      const payload = {
        date: data.date,
        billNo: data.billNo,
        companyID: data.companyID,
        customerID: data.customerID,
        grandTotal: data.grandTotal,
        detail: detailData,
        packingForwardingCharges: data.packingForwardingCharges,
      };
      const response = tag === 'add' ? await createBill({...payload, userID: loggedInUser.id, createdBy: loggedInUser.id }) : await updateBill({ ...payload, updatedBy: loggedInUser.id}, id);
      if (response.statusCode === 200) {
        showToast(response.message, true);
        if (tag === "add") {
          // socket.emit(`sendBillToWhatsApp_${loggedInUser.phoneNumber}`, response.data);
          const { success, message, data} = await fetchLoggedInUserData();
          if (success) {
            const latestBillNo = data.latestBillNo;
            localStorage.setItem('latestBillNo', latestBillNo);
          } else {
            showToast(message, false);
          }
          if (tag === 'add') {
            const fetchLastBillResponse = await getBillById(response.data.id);
            if(fetchLastBillResponse.statusCode === 200) {
              const printDetail = {
                company: {
                  name: loggedInUser?.px_company?.name,
                  address: loggedInUser?.px_company?.address,
                  phoneNo: loggedInUser?.px_company?.phoneNumber,
                  email: loggedInUser?.px_company?.email
                },
                customerName: fetchLastBillResponse.data?.px_customer?.name,
                billNo: fetchLastBillResponse.data?.billNo,
                date: fetchLastBillResponse.data?.date,
                detail: fetchLastBillResponse.data?.detail,
                totalQty: fetchLastBillResponse.data?.detail?.reduce((total, item) => (total + item.qty), 0),
                grandTotal: showTwoDecimal(fetchLastBillResponse.data?.grandTotal),
                totalInWord: fetchLastBillResponse.data ? convertAmountToWords(fetchLastBillResponse.data?.grandTotal) : '',
              }
              const printWindow = window.open("", "_blank", "popup=yes,menubar=no,toolbap=no");
              if (printWindow && printWindow.document) {
                printWindow.document.write(PrintBillContent(printDetail));
                printWindow.document.close();
                printWindow.onload = () => {
                  printWindow.print();
                  printWindow.close();
                };
              }
            }
          }
          reset();
          setValue('date', moment(new Date()).format('yyyy-MM-DD'));
          getNewBillNo();
        } else {
          navigate("/bill");
        }
      } else {
        showToast(response.message, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
  }

  const fetchEditBillData = useCallback(async () => {
    try {
      if (id) {
        dispatch(startLoading());
        const response = await getBillById(id);
        if (response?.statusCode === 200) {
          const { data } = response;
          setValue("date", moment(new Date(data.date)).format('yyyy-MM-DD'));
          setValue("billNo", data.billNo);
          setValue("companyID", data.companyID);
          setValue("customerID", data.customerID);
          setValue("grandTotal", data.grandTotal);
          setValue("packingForwardingCharges", data.packingForwardingCharges || '0');
          const items = data.detail.map((item) => {
            return {
              // id: item.id,
              index: item.index,
              itemID: item.itemID,
              qty: item.qty,
              rate: item.rate,
              discount: item.discount,
              total: item.total,
            };
          });
          setValue("detail", items);
        } else {
          showToast(response?.message, false);
        }
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      dispatch(stopLoading());
    }
    // eslint-disable-next-line
  }, [id, dispatch, setValue]);

  const handleItemSelect = (value, index) => {
    setValue(`detail.${index}.rate`, value?.rate);
    handleCalculateTotal(index);
  };

  const handleCalculateTotal = (index) => {
    const rate = parseFloat(getValues(`detail.${index}.rate`) || '0');
    const qty = parseFloat(getValues(`detail.${index}.qty`) || '0');
    const discount = parseFloat(getValues(`detail.${index}.discount`) || '0');
    let total = qty * rate;
    if(discount > 0) {
      total = total - (total * discount) / 100;
    }
    setValue(`detail.${index}.total`, showTwoDecimalWithoutRound(total.toString()));
    calculateGrandTotal();
  }

  const calculateGrandTotal = () => {
    const packingCharges = parseFloat(getValues('packingForwardingCharges') || '0');
    const detail = getValues("detail");
    const grandTotal = parseFloat(detail?.reduce((total, item) => (total + parseFloat(item.total)), 0));
    setValue(`grandTotal`, showTwoDecimal((grandTotal + packingCharges)));
  };
  
  // useEffect(() => {
  //   if(loggedInUser) {
  //     socket.connect();
  //     socket.emit('createSession', {phoneNumber: loggedInUser.phoneNumber})
  //     socket.emit(`isLogin_${loggedInUser.phoneNumber}`);
  //     socket.on(`ready_${loggedInUser.phoneNumber}`, (msg) => {
  //       console.log(msg);
  //       // setSessionCreated(true);
  //     });
  //     // socket.emit('createSession', {phoneNumber: loggedInUser.phoneNumber});
  //     socket.on(`failedBillToWhatsApp_${loggedInUser.phoneNumber}`, (detail) => {
  //       console.error(detail);
  //       showToast(detail.message, false);
  //     });
  //     return () => {
  //       socket.off(`failedBillToWhatsApp_${loggedInUser.phoneNumber}`);
  //       socket.disconnect();
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[loggedInUser]);

  useEffect(() => {
    const fetchDropDownList = async () => {
      try {
        dispatch(startLoading());
        const whereCondition = {
          isActive: true,
          isDeleted: false,
          companyID: loggedInUser.companyID
        };
        const payload = listPayload(0, whereCondition, 100000);
        const [
          customerResponse,
          itemResponse
        ] = await Promise.all([
          getCustomerList(payload),
          dropdownItem()
        ]);
        if (customerResponse?.statusCode === 200 && customerResponse.success) {
          setCustomers(customerResponse.data?.rows);
        } else {
          setCustomers([]);
        }
        if (itemResponse?.statusCode === 200 && itemResponse.success) {
          setItems(itemResponse.data);
        } else {
          setItems([]);
        }
      } catch (err) {
        showToast(err?.message, false);
      } finally {
        dispatch(stopLoading());
      }
    }
    fetchDropDownList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getNewBillNo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    tag === "edit" && fetchEditBillData();
  }, [tag, fetchEditBillData]);

  return {
    items,
    fields,
    control,
    customers,
    isPrintBtn,
    isSubmitting,
    selectedCompany,
    isSaveModalOpen,
    isViewDetailOpen,
    isCustomerModalOpen,
    reset,
    addRow,
    onSubmit,
    navigate,
    getValues,
    removeRow,
    handleSubmit,
    printHandler,
    setIsPrintBtn,
    handleItemSelect,
    setIsSaveModalOpen,
    calculateGrandTotal,
    handleCalculateTotal,
    toggleViewDetailOpen,
    setIsCustomerModalOpen,
  };
};
