import React from "react";
// import { QRCodeSVG } from "qrcode.react";

import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";

// import { useWhatsAppWebHooks } from "./useWhatsAppWebhooks"

const WhatsApp = () => {
    // const {
    //     qrCode,
    //     sessionCreated,
    //     handleLogout
    // } = useWhatsAppWebHooks();

    // const showQrCode = () => {
    //     if(qrCode) {
    //         return (
    //             <>
    //                 <Typography style={{
    //                     textAlign: 'center'
    //                 }} fontSize={22} fontWeight={600}>Connect Your Whats here for Direct sending bill to customer</Typography>
    //                 <br/>
    //                 <QRCodeSVG width="100%" size={256} value={qrCode}/>
    //             </>
    //         )
    //     } else {
    //         return null;
    //     }
    // }

    return (
        <Box>
            Work in progress
            {/* {sessionCreated ?
                <Box sx={{display: 'flex'}}>
                    <Typography fontSize={22} fontWeight={600}>
                        Already Connected With Web Whatsapp...
                    </Typography>&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" className="btn btn-tertiary" onClick={handleLogout}>Log Out</Button>
                </Box>
            :
                showQrCode()
            } */}
        </Box>
    )
}

export default WhatsApp;