import React from "react";
import { Controller } from "react-hook-form";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";


import { useAddEditCustomer } from "./hook/useAddEditCustomer";

const AddCustomer = ({ tag }) => {
  const { 
    company,
    control,
    isSuperAdmin,
    onSubmit,
    handleSubmit,
    cancelHandler 
  } = useAddEditCustomer(tag);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className="card">
          <FormGroup className="form-field">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="companyID"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      freeSolo
                      size="small"
                      id="companyID"
                      disabled={!isSuperAdmin}
                      options={company || []}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      value={company?.find((option) => option.id === value) ?? ''}
                      onBlur={onBlur}
                      onChange={(_event, value) => {
                        if(value) {
                          onChange(value?.id)
                        } else {
                          onChange(null);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Company"
                          error={!!error}
                          helperText={error?.message}
                        />
                      )}
                    />
                  )}
                  rules={{
                    required: "Please Select Company",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="name"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="Customer name"
                        size="small"
                        name="name"
                        value={value}
                        onChange={(e) => {
                          if(e.target.value.length < 75) {
                            onChange(e.target.value.toUpperCase())
                          }
                        }}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Customer name field required",
                  }}
                />
              </Grid>
              {/*  */}
              <Grid item xs={12} sm={3}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        type="number"
                        label="Phone"
                        size="small"
                        name="phoneNumber"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Phone number is required",
                    maxLength: {
                      value: 10,
                      message: "Phone number must be 10 digit",
                    },
                    minLength: {
                      value: 10,
                      message: "Phone number must be 10 digit",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Controller
                  name="relationName"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="Relation name"
                        size="small"
                        name="name"
                        value={value}
                        onChange={(e) => {
                          if(e.target.value.length < 50) {
                            onChange(e.target.value.toUpperCase())
                          }
                        }}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Relation name field required",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="address"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="Address"
                        size="small"
                        name="name"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                        multiline
                        rows={3}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Address field required",
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="addressCover"
                  control={control}
                  render={({
                    field: { onBlur, onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      size="small"
                      variant="standard"
                      className="form-control"
                    >
                      <TextField
                        label="Address Cover (For Next Line Use ',' )"
                        size="small"
                        name="name"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error?.message}
                        multiline
                        rows={3}
                        inputProps={{
                          autoComplete: 'off'
                        }}
                      />
                    </FormControl>
                  )}
                  rules={{
                    required: "Address Cover field required",
                  }}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Box>

        <Grid container spacing={3} sx={{ marginTop: "6px" }}>
          <Grid item md={1.5}>
            <Button type="submit" className="btn btn-tertiary">
              {tag === "add" ? "Save" : "Update"}
            </Button>
          </Grid>
          <Grid item md={1.5}>
            <Button className="btn btn-cancel" onClick={cancelHandler}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddCustomer;
