import React from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
// import routes from "./routes";
import { ToastContainer } from "react-toastify";

import Loader from "./components/Loader";
import { checkIsAuthenticated, getAuthToken } from "./utils/helper";

// css imports
import "./assets/styles/global.scss";
import "./assets/styles/sidebar.scss";
import "./assets/styles/header.scss";
import "./assets/styles/login.scss";
import "./assets/styles/customer.scss";
import "./assets/styles/home.scss";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login";
import LayoutProvider from "./layout";
import Home from "./pages/Home";
import ProtectedRoute from "./components/ProtectedRoute";
import Customer from "./pages/Customer";
import AddCustomer from "./pages/Customer/AddCustomer";
import Role from "./pages/Role";
import AddEditRole from "./pages/Role/addEditRole";
import Module from "./pages/Module";
import AddEditModule from "./pages/Module/addEditModule";
import PaymentType from "./pages/PaymentType";
import AddEditPaymentType from "./pages/PaymentType/AddEditPaymentType";
import AddEditBill from "./pages/Bill/AddEditBill";
import User from "./pages/User";
import AddEditUser from "./pages/User/AddEditUser";
import Rights from "./pages/Rights";
import BillReport from "./pages/Report/bill";
import Bill from "./pages/Bill";
import Report from "./pages/Report";
import NotFound from "./components/NotFound";
import NoConnection from "./components/NoConnection";
import useNoInternet from "./hook/useNoInternet";
import Company from "./pages/Company";
import AddEditCompany from "./pages/Company/AddEditCompany";
import Item from "./pages/Item";
import AddEditItem from "./pages/Item/AddEditItem";
import Setting from "./pages/Setting";
import AddEditSetting from "./pages/Setting/addEditSetting";
import Payment from "./pages/Payment";
import AddEditPayment from "./pages/Payment/addEditPayment";
import WhatsApp from "./pages/WhatsAppWeb";

const token = getAuthToken();

const App = () => {
  const { isOnline, pathname } = useNoInternet();

  const routes2 = createBrowserRouter([
    {
      path: "/",
      element: isOnline ?
        <LayoutProvider />
      : pathname.includes("bill") ? (
        <LayoutProvider />
      ) : null,
      errorElement: <NotFound />,
      loader:checkIsAuthenticated,
      children: [
        { index: true, element: isOnline ? <Home /> : <NoConnection /> },
        {
          path: 'whatsapp',
          element: (
            <ProtectedRoute
              path='whatsapp'
              Component={isOnline ? <WhatsApp/> : <NoConnection/>}
            />
          )
        },
        {
          path: 'payment',
          element: (
            <ProtectedRoute
              path='payment'
              Component={isOnline ? <Payment/> : <NoConnection/>}
            />
          )
        },
        {
          path: "add-payment",
          element: (
            <ProtectedRoute
              path="add-payment"
              Component={
                isOnline ? <AddEditPayment tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-payment/:id",
          element: (
            <ProtectedRoute
              path="edit-payment/:id"
              Component={
                isOnline ? <AddEditPayment tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: 'setting',
          element: (
            <ProtectedRoute
              path='setting'
              Component={isOnline ? <Setting/> : <NoConnection/>}
            />
          )
        },
        {
          path: "add-setting",
          element: (
            <ProtectedRoute
              path="add-setting"
              Component={
                isOnline ? <AddEditSetting tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-setting/:id",
          element: (
            <ProtectedRoute
              path="edit-setting/:id"
              Component={
                isOnline ? <AddEditSetting tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: 'item',
          element: (
            <ProtectedRoute
              path='item'
              Component={isOnline ? <Item/> : <NoConnection/>}
            />
          )
        },
        {
          path: "add-item",
          element: (
            <ProtectedRoute
              path="add-item"
              Component={
                isOnline ? <AddEditItem tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-item/:id",
          element: (
            <ProtectedRoute
              path="edit-item/:id"
              Component={
                isOnline ? <AddEditItem tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: 'company',
          element: (
            <ProtectedRoute
              path='company'
              Component={isOnline ? <Company/> : <NoConnection/>}
            />
          )
        },
        {
          path: "add-company",
          element: (
            <ProtectedRoute
              path="add-company"
              Component={
                isOnline ? <AddEditCompany tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-company/:id",
          element: (
            <ProtectedRoute
              path="edit-company/:id"
              Component={
                isOnline ? <AddEditCompany tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "customer",
          element: (
            <ProtectedRoute
              path="customer"
              Component={isOnline ? <Customer /> : <NoConnection />}
            />
          ),
        },
        {
          path: "add-customer",
          element: (
            <ProtectedRoute
              path="add-customer"
              Component={
                isOnline ? <AddCustomer tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-customer/:id",
          element: (
            <ProtectedRoute
              path="edit-customer/:id"
              Component={
                isOnline ? <AddCustomer tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "payment-type",
          element: (
            <ProtectedRoute
              path="payment-type"
              Component={isOnline ? <PaymentType /> : <NoConnection />}
            />
          ),
        },
        {
          path: "add-payment-type",
          element: (
            <ProtectedRoute
              path="add-payment-type"
              Component={
                isOnline ? <AddEditPaymentType tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-payment-type/:id",
          element: (
            <ProtectedRoute
              path="edit-payment-type/:id"
              Component={
                isOnline ? <AddEditPaymentType tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "user",
          element: (
            <ProtectedRoute
              path="user"
              Component={isOnline ? <User /> : <NoConnection />}
            />
          ),
        },
        {
          path: "add-user",
          element: (
            <ProtectedRoute
              path="add-user"
              Component={
                isOnline ? <AddEditUser tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-user/:id",
          element: (
            <ProtectedRoute
              path="edit-user/:id"
              Component={
                isOnline ? <AddEditUser tag="edit" /> : <NoConnection />
              }
            />
          ),
        },

        {
          path: "bill",
          element: (
            <ProtectedRoute 
              path="bill" 
              Component={
                isOnline ? <Bill/> : <NoConnection/>
              }
            />
          ),
        },
        {
          path: "create-bill",
          element: (
            <ProtectedRoute
              path="create-bill"
              Component={
                isOnline ? <AddEditBill tag="add" /> : <NoConnection/>
              }
            />
          ),
        },
        {
          path: "edit-bill/:id",
          element: (
            <ProtectedRoute
              path="edit-bill/:id"
              Component={
                isOnline ? <AddEditBill tag="edit" /> : <NoConnection/>
              }
            />
          ),
        },

        {
          path: "role",
          element: (
            <ProtectedRoute
              path="role"
              Component={isOnline ? <Role /> : <NoConnection />}
            />
          ),
        },
        {
          path: "add-role",
          element: (
            <ProtectedRoute
              path="add-role"
              Component={
                isOnline ? <AddEditRole tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-role/:id",
          element: (
            <ProtectedRoute
              path="edit-role/:id"
              Component={
                isOnline ? <AddEditRole tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "rights",
          element: (
            <ProtectedRoute
              path="rights"
              Component={isOnline ? <Rights /> : <NoConnection />}
            />
          ),
        },
        {
          path: "module",
          element: (
            <ProtectedRoute
              path="module"
              Component={isOnline ? <Module /> : <NoConnection />}
            />
          ),
        },
        {
          path: "add-module",
          element: (
            <ProtectedRoute
              path="add-module"
              Component={
                isOnline ? <AddEditModule tag="add" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "edit-module/:id",
          element: (
            <ProtectedRoute
              path="edit-module/:id"
              Component={
                isOnline ? <AddEditModule tag="edit" /> : <NoConnection />
              }
            />
          ),
        },
        {
          path: "reports",
          element: (
            <ProtectedRoute
              path="reports"
              Component={isOnline ? <Report /> : <NoConnection />}
            />
          ),
        },
        {
          path: 'reports/bill',
          element: (
            <ProtectedRoute
              path="reports/bill"
              Component={isOnline ? <BillReport /> : <NoConnection />}
            />
          )
        }
      ],
    },
    { path: "login", element: !token ? <Login /> : <Navigate to="/" /> },
    { path: "*", element: <NotFound /> },
  ]);

  return (
    <>
      <ToastContainer />
      <Loader />
      <RouterProvider router={routes2} />
    </>
  );
};

export default App;
