import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { listPayload, rightsAccess, showToast, showTwoDecimal, convertAmountToWords } from "../../../utils/helper";
import { billAction } from "../../../redux/bill";
import {
  deleteBill,
  getBillList,
} from "../../../service/bill";
import {
  postBillOnWhatsApp
} from "../../../service/whatsapp";
import { startLoading, stopLoading } from "../../../redux/loader";
import { PrintBillContent } from "../../../components/PrintContent";

export const useBill = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // eslint-disable-next-line
  const billData = useSelector((state) => state.bill.data);
  const loggedInUser = useSelector((state) => state.loggedInUser);
  const userRole = loggedInUser?.px_role?.name?.toLowerCase();
  const { accessModules } = loggedInUser;

  const [deleteId, setDeleteId] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // pagination start
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0);

  const visibleRows = useMemo(() => {
    // return combinedData;
    return billData;
  }, [billData]);

  const isAdmin = useMemo(() => {
    if(loggedInUser && loggedInUser.px_role && ['super admin'].includes(loggedInUser.px_role.name.toLowerCase())) {
        return true;
    }
    return false;
  }, [loggedInUser]);

  // pagination end

  const rights = useMemo(() => {
    return rightsAccess(accessModules, pathname);
  }, [accessModules, pathname]);

  //  fetch bill
  const fetchBillData = useCallback(
    async (searchValue = "") => {
      try {
        dispatch(startLoading());
        let whereCondition = {
          isDeleted: false,
          searchText: searchValue,
          companyID: loggedInUser.companyID
        };
        // if (!['super admin'].includes(userRole)) {
        //   whereCondition = {
        //     ...whereCondition,
        //     companyID: loggedInUser.companyID
        //   };
        // }

        const body = listPayload(page, whereCondition, rowsPerPage, {
          sortBy: "id",
        });

        const response = await getBillList(body);

        if (response?.statusCode === 200) {
          const finalPayload = [
            ...response?.data?.rows,
          ];
          setCount(response.data.count);
          dispatch(billAction.storeBill(finalPayload));
        } else {
          const payload = [];
          setCount(0);
          dispatch(billAction.storeBill(payload));
        }
      } catch (error) {
        showToast(error?.message, false);
      } finally {
        dispatch(stopLoading());
      }
    },
    [dispatch, page, loggedInUser.companyID, rowsPerPage]
  );

  // search bill
  const searchBillHandler = async (payload) => {
    try {
      fetchBillData(payload.searchValue);
    } catch (error) {
      showToast(error?.message, false);
    }
  };

  useEffect(() => {
    if(loggedInUser && loggedInUser.id) {
      fetchBillData();
    }
  }, [fetchBillData, loggedInUser]);

  // delete payment type click handler
  const deleteBtnClickHandler = (id) => {
    setDeleteId(id);
    setIsDeleteModalOpen(true);
  };

  // delete bill
  const deleteHandler = async () => {
    try {
      dispatch(startLoading());

      const response = await deleteBill(deleteId);

      if (response?.statusCode === 200) {
        showToast(response?.message, true);
        dispatch(billAction.removeBill({ id: deleteId }));
        setCount((prev) => prev - 1);
      } else {
        showToast(response?.messageCode, false);
      }
    } catch (error) {
      showToast(error?.message, false);
    } finally {
      setIsDeleteModalOpen(false);
      dispatch(stopLoading());
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePrint = (detail) => {
    const printDetail = {
      company: {
        name: loggedInUser?.px_company?.name,
        address: loggedInUser?.px_company?.address,
        phoneNo: loggedInUser?.px_company?.phoneNumber,
        email: loggedInUser?.px_company?.email
      },
      customerName: detail?.px_customer?.name,
      billNo: detail?.billNo,
      date: detail?.date,
      detail: detail?.detail,
      totalQty: detail?.detail?.reduce((total, item) => (total + item.qty), 0),
      grandTotal: showTwoDecimal(detail?.grandTotal),
      totalInWord: detail ? convertAmountToWords(detail?.grandTotal) : '',
    }
    const printWindow = window.open("", "_blank", "popup=yes,menubar=no,toolbap=no");
    if (printWindow && printWindow.document) {
      printWindow.document.write(PrintBillContent(printDetail));
      printWindow.document.close();
      printWindow.onload = () => {
        printWindow.print();
        printWindow.close();
      };
    }
  }

  return {
    page,
    count,
    rights,
    isAdmin,
    userRole,
    visibleRows,
    rowsPerPage,
    isDeleteModalOpen,
    handlePrint,
    deleteHandler,
    handleChangePage,
    searchBillHandler,
    postBillOnWhatsApp,
    setIsDeleteModalOpen,
    deleteBtnClickHandler,
    handleChangeRowsPerPage,
  };
};
